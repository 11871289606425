import React, { useState,useEffect } from "react";
import userQueries from '../../queries/userQueries';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';

const CreateVideos = () => {
    const [susmsg, setSusmsg] = useState(false);
    const [ordermsg, setOrdermsg] = useState(false);
    const navigate = useNavigate();
    const { register, handleSubmit, getValues,formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false);
    const AddInfodata = userQueries.useCreatevideosMutation((response) => {
        console.log("res",response.message)
        if (response?.message === "Success") {
            setSusmsg(true);
            alert(response.data.publishstatus === 0 ? "Saved as draft" : "Published successfully");
            navigate('/videoslist');
        }
        
        
       
    });

   
   
    const handleFormSubmit = async (data, publishStatus) => {
        setSusmsg(false);
        setLoading(true);
        const videosdata = {
            title: data.title,
            link: data.link,
            publishstatus: publishStatus,
            displayorder: data.displayorder

        };
        try {
            const createdUser = await AddInfodata.mutateAsync(videosdata);
           
            
        } catch (error) {
            console.error('Error occurred:', error);
        }
    };

    return (
        <>
            <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
                Create Videos
            </h4>
            <form className="max-w-lg mx-auto grid grid-cols-2 gap-4">
                <div className="mb-5">
                    <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Title</label>
                    <input 
                        type="text" 
                        id="title" 
                        {...register("title", { required: "Title is required" })} 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    />
                    {errors.title && <div className="text-red-600 text-s">{errors.title.message}</div>}
                </div>

                <div className="mb-5">
                    <label htmlFor="link" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Link</label>
                    <input 
                        type="text" 
                        id="link" 
                        {...register("link", { 
                            required: "Link is required",
                            pattern: {
                                value: /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/,
                                message: "Please enter a valid YouTube link"
                            }
                        })} 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    />
                    {errors.link && <div className="text-red-600 text-s">{errors.link.message}</div>}
                </div>

                <div className="mb-5">
                    <label htmlFor="displayorder" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Display Order</label>
                    <input 
                        type="number" 
                        id="displayorder" 
                        {...register("displayorder", { required: false })} 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    />
                    {errors.displayorder && <div className="text-red-600 text-s">{errors.displayorder.message}</div>}
                    {ordermsg && <div className="text-red-400 text-lg ml-6 text-center inline-block whitespace-nowrap">
                        Display Order already exists
                    </div>}
                </div>
               

                <button 
                type="button" 
                onClick={() => handleFormSubmit(getValues(), 0)}
                className="col-span-2 w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-3"
            >
                Save as draft
            </button>

            <button 
                type="button"
                onClick={handleSubmit((data) => handleFormSubmit(data, 1))}
                className="col-span-2 w-full text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
                Save and publish
            </button>
    
                
                {susmsg && <div className="text-green-600 text-lg ml-6 text-center inline-block whitespace-nowrap">
                    Videos created successfully
                </div>}

              
            </form>
            {loading && (
                <Loader />  
            )}
        </>
    );
};

export default CreateVideos;
