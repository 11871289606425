import React from 'react';

const GoogleMap = () => {
    React.useEffect(() => {
        // Load the Google Maps JavaScript API script
        const googleMapScript = document.createElement('script');
        googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA-g3x5rdIO5kDV5Qy6mDCO_wSHCZfCOik`;
        googleMapScript.async = true;
        window.document.body.appendChild(googleMapScript);
        googleMapScript.addEventListener('load', () => {
            initMap();
        });
    }, []);

    const initMap = () => {
        // The location of your event (example: Kozhikode)
        const eventLocation = { lat: 11.26283391323257, lng: 75.76730194673915 };
        // The map, centered at the event location
        const map = new window.google.maps.Map(document.getElementById("map"), {
            zoom: 15,
            center: eventLocation,
        });
        // The marker, positioned at the event location
        new window.google.maps.Marker({
            position: eventLocation,
            map: map,
        });
    };
    return (
        <div className="google-map-container">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 p-0 text-center">
                        <h2 data-aos="fade-up" data-aos-delay="100">
                            <span className="speaker-pattern"></span>Event Location<span className="speaker-pattern"></span>
                        </h2>
                    </div>
                    <div className="col-md-12 pt-5 pb-5 mt-5">
                        <div id="map" style={{ width: '100%', height: '400px' }}></div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default GoogleMap;