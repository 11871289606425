import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col, Alert } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import authQueries from '../queries/authQueries';

const schema = yup.object().shape({
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  phone: yup.string().required('Phone number is required'),
  gender: yup.string(),
  pin_code: yup.string()
});

const RegisterModal = ({ isOpen, onClose }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const { control, handleSubmit,reset, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      age: '',
      gender: '',
      pin_code: ''
    }
  });

  //const userCreate = authQueries.useUserCreateMutation();

  const userCreate = authQueries.useUserCreateMutation(async (response) => {
    });

  const onSubmit = async (data) => {
    setErrorMessage(''); // Clear any previous error messages
    const submissionData = {
      ...data,
      username: data.email,
      role: "USER", // Adding the role field with default value "USER"
      password: "123"
    };
    console.log("Submission data:", submissionData);

    try {
      const response = await userCreate.mutateAsync(submissionData);
      console.log("API response:", response);
      
      if (response.message === 'Success') {
        reset(); // Reset the form
        console.log("Registration successful");
        onClose(false);
        // You might want to show a success message or redirect the user
      } else {
        setErrorMessage(response.message || 'Registration failed. Please try again.');
      }
    } catch (error) {
      console.error("Error during registration:", error);
      
      if (error.response) {
        setErrorMessage(error.response.data.message || 'An error occurred during registration.');
      } else if (error.request) {
        setErrorMessage('No response received from the server. Please try again.');
      } else {
        setErrorMessage('Email Already Exist.');
      }
    }
  };

  return (
    <Modal show={isOpen} onHide={onClose} className="register-popup">
      <Modal.Header closeButton style={{backgroundColor:'#00bfc0'}}>
        <Modal.Title className="text-white">Register</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && (
          <Alert variant="danger" onClose={() => setErrorMessage('')} dismissible>
            {errorMessage}
          </Alert>
        )}
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>First Name:</Form.Label>
                <Controller
                  name="first_name"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      type="text"
                      placeholder="Enter first name"
                      isInvalid={!!errors.first_name}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.first_name?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Last Name:</Form.Label>
                <Controller
                  name="last_name"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      type="text"
                      placeholder="Enter last name"
                      isInvalid={!!errors.last_name}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.last_name?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mb-3">
            <Form.Label>Email:</Form.Label>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Form.Control
                  {...field}
                  type="email"
                  placeholder="Enter email address"
                  isInvalid={!!errors.email}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Phone:</Form.Label>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <Form.Control
                  {...field}
                  type="tel"
                  placeholder="Enter phone number"
                  isInvalid={!!errors.phone}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.phone?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Row>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Age:</Form.Label>
                <Controller
                  name="age"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      type="number"
                      placeholder="Enter your age"
                      isInvalid={!!errors.age}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.age?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Gender:</Form.Label>
                <Controller
                  name="gender"
                  control={control}
                  render={({ field }) => (
                    <Form.Select
                      {...field}
                      isInvalid={!!errors.gender}
                    >
                      <option value="">Select gender</option>
                      <option value="MALE">MALE</option>
                      <option value="FEMALE">FEMALE</option>
                      <option value="OTHERS">OTHERS</option>
                    </Form.Select>
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.gender?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Pin Code:</Form.Label>
                <Controller
                  name="pin_code"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      type="text"
                      placeholder="Enter pin code"
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>

          <div className="text-end">
            <Button variant="primary" type="submit" className="mt-3">
              Register
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default RegisterModal;