import React, {useState} from 'react'
import Navbar from './Navbar';
import { useLocation } from 'react-router-dom';
import Footer from './Footer';

export default function SpeakerviewSite() {

    const [isOpen, setIsOpen] = useState(false);
    const handleClose = () => setIsOpen(false);
    const handleOpen = () => setIsOpen(true);
    const location = useLocation();
    const stateData = location.state;

    console.log(stateData);

    const decodeHtml = (html) => {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = html;
        return textArea.value;
      };

  return (
    <>
        <Navbar handleOpen={handleOpen}  />
        <div class="innerbanner container-fluid">
        <div class="container">

            <div class="row">
                <div class="col-md-12">
                    <span class="logo-strips"></span> <h2>Speaker: <span>{stateData?.heading}</span></h2>
                </div>
            </div>
        </div>
        </div>

        <div class="speakers-wrpr speaker-details-wrpr">
	        <div class="container">
		        <div class="row">
			
						<div class="col-md-3 pb-5 text-center">
							<img  src={`${process.env.REACT_APP_BASE_URL}/${stateData.photo}`} />
							<div class="speaker-name">{stateData?.heading}</div>
							{/* <div class="speaker-designation">Speaker Designation</div> */}
							
						</div>
						<div class="col-md-6">
                        <div 
                        className={`prose max-w-none mb-8 text-gray-600 leading-relaxed`}
                        dangerouslySetInnerHTML={{
                        __html: decodeHtml(stateData.content)
                        }}
                        />
							
						</div>
						{/* <div class="col-md-3 speaker-Session-cntnr">
							<div class="">
								<h5>നീതി, നിയമം, സമൂഹം</h5>
								<p>3 Nov | 12:00 pm - 12:45 pm</p>
							</div>
						</div> */}
		        </div>
	        </div>
        </div>

        <Footer />
    </>
  )
}
