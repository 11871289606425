import React, { useState, useEffect } from 'react';
import { Search,Edit } from 'lucide-react';
import userQueries from '../../queries/userQueries';
import { userStore } from '../../store/userStore';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../common/Pagination/Pagination';
import Loader from '../Loader/Loader';
import Breadcrumb from '../Breadcrumbs/Breadcrumb';

const AdminListing = () => {      
  const [users, setUsers] = useState([]);   
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { searchResults } = userStore();
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    fetchUsers();
  }, [currentPage, pageSize, searchTerm]);

  useEffect(() => {
    if (searchResults?.length > 0) {
      setUsers(searchResults);
      setTotalCount(searchResults?.length);
      setCurrentPage(0);
    }
  }, [searchResults]);

  const fetchUsers = () => {
    const params = new URLSearchParams({
      page: currentPage,
      size: pageSize,
      search: searchTerm
    });
    speakerlist.mutate(params.toString());
  };

  const speakerlist = userQueries.useAdminListingMutation(
    async (response) => {
      // console.log('response is', response);
      setUsers(response?.data?.items);
      setTotalCount(response?.data?.totalItems);
      setTotalPage(response?.data?.totalPages);
      setIsLoading(false);
    },
    {
      onError: (error) => {
        console.error('Userlist fetch error:', error);
        setError('Failed to fetch user list. Please try again later.');
      }
    }
  );


  const handleSearch = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    setCurrentPage(0); 
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };


  // const totalPages = Math.ceil(totalCount / pageSize);
  const totalPages = totalPage;
  // console.log(">>>>>>>",totalPages)

  const handleEditSession = (user) => {
    navigate(`/adminedit/${user.user_detail.user_id}`);
  }

  
  
  return (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
     <div className="flex justify-between items-center mb-4">
      <Breadcrumb pageName="Admins"/>
      </div>

      <div className="mb-4 flex items-center">
        <Search className="mr-2 h-5 w-5 text-gray-500" />
        <input
          type="text"
          placeholder="Search..."
          className="w-full rounded-md border border-stroke bg-transparent py-2 pl-10 pr-4 outline-none focus:border-primary dark:border-strokedark dark:bg-meta-4 dark:focus:border-primary"
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>

        {/* Display the count */}
        <div className="mb-4 text-gray-700 dark:text-gray-300">
        {users?.length} out of {totalCount} users found
      </div>
      {isLoading ? (
      <Loader /> 
    ) : (
        <>
      <div className="flex flex-col">
      <div className="border-b border-stroke text-black dark:border-strokedark grid grid-cols-[90px_.8fr_1fr_1fr_90px_1fr_1fr] sm:grid-cols-[90px_.8fr_1fr_1fr_90px_1fr_1fr] bg-gray-800 dark:bg-meta-4">
      <div className="p-2.5 xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              Sl no.
            </h5>
          </div>
          <div className="p-2.5 xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              Name
            </h5>
          </div>
          <div className="p-2.5 xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              Email
            </h5>
          </div>
          <div className="p-2.5 xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              Phone
            </h5>
          </div>
          <div className="hidden p-2.5 sm:block xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              Age
            </h5>
          </div>
          <div className="hidden p-2.5 sm:block xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              Pincode
            </h5>
          </div>
          <div className="hidden p-2.5 sm:block xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              Edit
            </h5>
          </div>
        </div>

       
          {users?.map((user, key) => (
            <div
              className={`grid grid-cols-[90px_.8fr_1fr_1fr_90px_1fr_1fr] sm:grid-cols-[90px_.8fr_1fr_1fr_90px_1fr_1fr] bg-gray-800 dark:bg-meta-4 ${
                key === users?.length - 1
                  ? ''
                  : 'border-b border-stroke dark:border-strokedark'
              }`}
              key={key}
            >
            
              <div className="p-2.5 xl:p-5">
                <p className="hidden text-black dark:text-white sm:block">
               
                {currentPage * pageSize + key + 1}
                </p>
              </div>
              <div className="p-2.5 xl:p-5">
                <p className="hidden text-black dark:text-white sm:block">
                  {user.user_detail.first_name} {user.user_detail.last_name}
                </p>
              </div>

              <div className="p-2.5 xl:p-5 overflow-hidden text-ellipsis">
                <p className="text-meta-3">{user.user_detail.email}</p>
              </div>

              <div className="p-2.5 xl:p-5">
                <p className="text-black dark:text-white">{user.user_detail.phone}</p>
              </div>

              <div className="p-2.5 xl:p-5">
                <p className="text-black dark:text-white">{user.user_detail.age}</p>
              </div>
              <div className="p-2.5 xl:p-5">
                <p className="text-black dark:text-white">{user.user_detail.pin_code}</p>
              </div>

               {/* Edit icon */}
               <div className="p-2.5 xl:p-5">
               {/* <button onClick={() => navigate(`/adminedit/${user.user_detail.user_id}`)}> */}
               <button onClick={() => {handleEditSession(user)}}>
                 <Edit className="h-5 w-5 text-blue-500 cursor-pointer hover:text-blue-700" />
               </button>
             </div>

            </div>
          ))
        }
      </div>

     

      <div className="mt-4 flex justify-center">
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
      
    </div>
    </>
      )}
    </div>
  );
};
export default AdminListing;