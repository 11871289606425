import React, { useState, useRef } from "react";
import userQueries from '../../queries/userQueries';
// import speakerQueries from '../../queries/speakerQueries';
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
import RichTextEditor from "../Rte/Rteeditor";
const AddSpeaker = () => {
    const [usernameExist, setUsernameExist] = useState('');
    const [susmsg, setSusmsg] = useState(false);
    const [usernameerror, setUsernameerror] = useState(false);
    const [error, setError] = useState(false);
    const [imageError, setImageError] = useState('');
    const [gendererror, setGendererror] = useState(false);
    const [loading, setLoading] = useState(false); // Loader state
    const navigate = useNavigate();
    const imageRef = useRef(null);

    const { register, handleSubmit,getValues, control, formState: { errors } } = useForm();

    const AddInfodata = userQueries.useCreatespeakerMutation((response) => {
       
        if (response?.message === "Success") {
            setSusmsg(true);
            alert(response?.data.status === 'Unpublish' ? "Saved as draft" : "Published successfully");
            
          
        }
        else if(response?.message === "User Exists with this Phone")
        {
            alert("User Exists with this phone number")
            setLoading(false); 
        }
        else if(response?.message === "User Exists with this Email")
        {
            alert("User Exists with this Email")
            setLoading(false); 
        }
        else if(response?.message === "User Exist")
        {
            alert("User Exists with this Email or Phone Number")
            setLoading(false); 
        }
        
       
    });

    const UpdateUserImage = userQueries.useSpeakerimageMutation((response) => {
        if (response?.message === "Profile image uploaded successfully") {
            setSusmsg(true);
        }
    });

    const handleFormSubmit = async (data, publishStatus) => {
        setUsernameExist(false);
        setSusmsg(false);
     
        
        if(publishStatus === "Publish"){

            if (!imageRef.current.files[0]) {
                setImageError("Image is required.");
                return; // Stop here if no image
            } else {
                setImageError(""); // Clear error if image is selected
            }
        }
       
        // Build user data
        const userData = {
            username: data.email,
            heading: data.heading,
            email: data.email,
            phone: data.phone,
            content: data.content,
            role: 'SPEAKER',
            status: publishStatus, 
            display_order: data.displayorder || 1,
        };
    
        try {

            setLoading(true); // Show loader when submission starts
            const createdUser = await AddInfodata.mutateAsync(userData);

            
            
           

            if (createdUser?.message === 'Success') {
                if (imageRef.current.files[0]) {
                    const formData = new FormData();
                    formData.append('speakers', imageRef.current.files[0]);
                    formData.append('userId', createdUser.data.id);
                    await UpdateUserImage.mutateAsync(formData);
                }
                navigate('/speakerlisting');
               
            }
    
        } catch (error) {
            console.log('Error occurred:', error);

            if (error.response?.status === 409) {
                const errorMessage = error.response?.data?.message || 'Unknown error';
                if (errorMessage === 'username already exists') {
                    
                    setUsernameExist(true);
                }
            } else {
                alert('An unexpected error occurred');
            }
            setLoading(false); // Hide loader if error occurs
        }
    };

    return (
        <>
            <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
                Create Speaker
            </h4>
            <form className="max-w-lg mx-auto grid grid-cols-2 gap-4">
                {/* First Name */}
                <div className="mb-5 col-span-2">
                    <label htmlFor="heading" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Heading</label>
                    <input 
                        type="text" 
                        id="heading" 
                        {...register("heading", { required: "Heading is required" })} 
                        name="heading" 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    />
                    {errors.heading && <div className="text-red-600 text-s">{errors.heading.message}</div>}
                </div>

                <div className="mb-5 col-span-2">
                    <label htmlFor="content" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Content</label>
                    {/* <textarea 
                        id="content" 
                        {...register("content", { required: "Content is required" })} 
                        name="content" 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                        rows="4"
                    />
                    {errors.content && <div className="text-red-600 text-s">{errors.content.message}</div>}
                </div> */}
                   <Controller
                    name="content"
                    control={control}
                    rules={{ required: "Content is required" }}
                    render={({ field }) => (
                        <RichTextEditor
                            {...field}
                            id="content"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            rows="4"
                        />
                    )}
                />
                {errors.content && (
                    <div className="text-red-600 text-sm">{errors.content.message}</div>
                )}
            </div>

                <div className="mb-5 col-span-2">
                    <label htmlFor="image" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Upload Image</label>
                    <input 
                        type="file" 
                        id="image" 
                        ref={imageRef}
                        accept="image/*"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    />
                    {imageError && <div className="text-red-600 text-s">{imageError}</div>}
                    <span className="form-text text-warning small">
                    Recommended image size: less than 256 KB.
                  </span>
                </div>
                

                <div className="mb-5 col-span-1">
                <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone Number</label>
                <input
                    type="tel"
                    id="phone"
                    {...register("phone", {
                        
                        pattern: {
                            value: /^(?:\+91|91)?\d{10}$/,
                            message: "Please enter a  valid phone number"
                        }
                    })}
                    name="phone"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                />
                {errors.phone && <div className="text-red-600 text-sm">{errors.phone.message}</div>}
            </div>

                <div className="mb-5 col-span-1">
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                    <input 
                        type="email" 
                        id="email" 
                        {...register("email")} 
                        name="email" 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    />
                    {errors.email && <div className="text-red-600 text-s">{errors.email.message}</div>}
                </div>

                {usernameExist && (
                    <div className="flex justify-center">
                        <div className="text-red-600 text-s">
                            {usernameerror}
                        </div>
                    </div>
                )}

                <div className="mb-5">
                    <label htmlFor="displayorder" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Display Order</label>
                    <input 
                        type="number" 
                        id="displayorder" 
                        {...register("displayorder", { required: false })} 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    />
                   
                </div>


                <button 
                    type="button" 
                    onClick={() => handleFormSubmit(getValues(), 'Unpublish')}
                    className="col-span-2 w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-3"
                >
                    Save as draft
                </button>

                <button 
                    type="button"
                    onClick={handleSubmit((data) => handleFormSubmit(data, 'Publish'))}
                    className="col-span-2 w-full text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                    Save and publish
                </button>
                    {/* Loader */}
                    {loading && (
                        <Loader />
                    )}
                {susmsg && 
                    <div className="text-green-600 text-lg ml-6 text-center inline-block whitespace-nowrap">
                        User created successfully
                    </div>
                }
            </form>
        </>
    );
};

export default AddSpeaker;
