import React, {useEffect, useState} from 'react'
import Footer from './Footer';
import Navbar from './Navbar';
import InitModal from './initModal';
import { useScrollToTop } from '../hooks/ScrollTop';
import siteQueries from '../queries/siteQueries';
import Pagination from '../common/Pagination/Pagination';
import Loader from '../components/Loader/Loader';
import 'venobox/dist/venobox.min.css';
import { useNavigate } from 'react-router-dom';

export default function SpeakerListingsite() {

    const [isOpen, setIsOpen] = useState(false);
    const [speaker, setSpeaker] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [totalPage, setTotalPage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
 
    const handleClose = () => setIsOpen(false);
    const handleOpen = () => setIsOpen(true);
    const navigate = useNavigate();
    
    const Speaker = siteQueries.useSpeakerListingMutation(
        async (response) => {
          setSpeaker(response?.data?.items);
          setTotalCount(response?.data?.totalItems);
          setTotalPage(response?.data?.totalPages);
          setIsLoading(false);
        },
      );

      useEffect(() => {
        Speaker.mutateAsync({
            page: currentPage,
            size: pageSize,
        })
      }, [currentPage, pageSize,]);
     
      const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
      };

      const totalPages = totalPage;

      const decodeHtml = (html) => {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = html;
        return textArea.value;
      };
   

      const handleNavigateWithState = (speaker) => {
        navigate('/speakerview', { state: speaker });
      };
      

  useScrollToTop();
  return (
    <div>
        {isLoading ? (
      <Loader /> 
    ) : (
        <>
        <Navbar handleOpen={handleOpen}  />
        <div class="innerbanner container-fluid">
            <div class="container">

                <div class="row">
                    <div class="col-md-12">
                    <span class="logo-strips"></span> <h2>Speakers</h2>
                    </div>
                </div>
            </div>
        </div>
            <div>
            <div className="container my-4">
            <div className="container my-5">
      <div className="row">
        {speaker?.map((speaker, index) => (
          <div onClick={()=>{handleNavigateWithState(speaker)}} style={{cursor:'pointer'}} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" key={index}>
            <div className="text-center">
              <div
                className="rounded-circle overflow-hidden mx-auto mb-3"
                style={{ width: '150px', height: '150px' }}
              >
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/${speaker?.photo}`}
                  alt={speaker?.name}
                  className="w-100 h-100"
                  style={{ objectFit: 'cover' }}
                />
              </div>
              <h5 className="font-weight-bold">{speaker?.heading}</h5>
              <div  style={{
                display: '-webkit-box',
                WebkitLineClamp: 6, 
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }} dangerouslySetInnerHTML={{
                  __html: decodeHtml(speaker?.content)
                }}
                />
            </div>
          </div>
        ))}
      </div>
    </div>
               
                <div 
                className="d-flex justify-content-center mt-4">
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
                </div>
                </div>
                
            </div>
                
        <Footer handleOpen={handleOpen} />

        <InitModal isOpen={isOpen} onClose={handleClose} />
        </>
    )}
    
    </div>
  )
}