import React, { useState, useEffect } from 'react';
import { Search,Edit } from 'lucide-react';
import userQueries from '../../queries/userQueries';
import { userStore } from '../../store/userStore';
import { useNavigate } from 'react-router-dom';
import RouteConstants from '../../constant/Routeconstant';
import Breadcrumb from '../Breadcrumbs/Breadcrumb';
import Pagination from '../../common/Pagination/Pagination';
import Loader from '../Loader/Loader';
import { PencilSquareIcon ,TrashIcon} from '@heroicons/react/24/solid';
import { Eye } from 'lucide-react';


const SpeakerListing = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { searchResults } = userStore();
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [response, setResponse] = useState();
  const [totalPage, setTotalPage] = useState(0);
  const navigate = useNavigate();
  

  const speakerlist = userQueries.useSpeakerlistMutation(
   
    async (response) => {
      setResponse(response?.data)
      setUsers(response?.data?.items);
      setTotalCount(response?.data?.totalItems);
      setTotalPage(response?.data?.totalPages);
      setIsLoading(false);
    },
    {
      onError: (error) => {
        setError('Failed to fetch user list. Please try again later.');
      }
    }
  );

  useEffect(() => {
    setIsLoading(true);
    fetchUsers();
  }, [currentPage, pageSize, searchTerm]);

  useEffect(() => {
    if (searchResults?.length > 0) {
      setUsers(searchResults);
      setTotalCount(searchResults?.length);
      setCurrentPage(0);
    }
  }, [searchResults]);

  const fetchUsers = () => {
    const params = new URLSearchParams({
      page: currentPage,
      size: pageSize,
      search: searchTerm
    });
    speakerlist.mutate(params.toString());
  };

  const handleSearch = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    setCurrentPage(0); // Reset to first page on new search
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  let totalPages = totalPage;
  // const totalPages = Math.ceil(totalCount / pageSize);

  const handleAddSpeaker = () => {
    navigate(RouteConstants.ADDSPEAKER); 
  };

  const publishspeaker = userQueries.usePublishspeakerMutation(
    
  (response) => {
    if (response?.message === "published successfully") {
        alert("published successfully")
        navigate('/speakerlisting');
    }
    else if (response?.message === "unpublished successfully") {
      alert("unpublished successfully")
      navigate('/speakerlisting');
    }
}
);

  const handlepublish = (id) => {
    publishspeaker.mutateAsync(id);
  }

  const deletespeakers = userQueries.useDeletespeakerMutation(
    (response) => {
       
       alert("Successfully deleted")
       navigate('/speakerlisting')
       
    }
  );

    const handleDelete = async (event, id) => {
      event.preventDefault();
      if (window.confirm('Are you sure you want to delete this item?')) {
        await deletespeakers.mutateAsync(id);
      }
    }
  
    const decodeHtml = (html) => {
      const textArea = document.createElement('textarea');
      textArea.innerHTML = html;
      return textArea.value;
    }; 

    const handleNavigateWithState = (user) => {
      navigate('/speaker-view', { state: user });
    };
    
  return (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      <div className="flex justify-between items-center mb-4">
        <Breadcrumb pageName="Speakers" />
        <div className="flex flex-col sm:flex-row gap-2">
         

          

          <button 
            className="bg-blue-500 hover:bg-blue-600 text-white rounded-full p-2 shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
            title="Add Speaker"
            aria-label="Add Speaker"
            onClick={handleAddSpeaker}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
            </svg>
          </button>
        </div>
      </div>

      <div className="mb-4 flex items-center">
        <Search className="mr-2 h-5 w-5 text-gray-500" />
        <input
          type="text"
          placeholder="Search..."
          className="w-full rounded-md border border-stroke bg-transparent py-2 pl-10 pr-4 outline-none focus:border-primary dark:border-strokedark dark:bg-meta-4 dark:focus:border-primary"
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>

      <div className="mb-4 text-gray-700 dark:text-gray-300">
        {users?.length} out of {totalCount} users found
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="flex flex-col">
            <div className="grid grid-cols-[0.5fr_.8fr_1.5fr_1fr_1.5fr_1.5fr_.5fr_.5fr] sm:grid-cols-[0.5fr_.8fr_1.5fr_1fr_1.5fr_1.5fr_.5fr_.5fr] border-b border-stroke bg-gray-800 dark:bg-meta-4">
              <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Sl no.</div>
              <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Heading</div>
              <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Content</div>
              <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Image</div>
              <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Phone Number</div>
              <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Email</div>
              <div className="p-2 text-xs font-medium uppercase xsm:text-sm">publish status</div>
              <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Actions</div>
              
            </div>

            {users?.map((user, key) => (
              <div
                key={key}
                className="grid grid-cols-[0.5fr_.8fr_1.5fr_1fr_1.5fr_1.5fr_.5fr_.5fr] sm:grid-cols-[0.5fr_.8fr_1.5fr_1fr_1.5fr_1.5fr_.5fr_.5fr] border-b border-stroke bg-gray-200 dark:bg-meta-4"
              >
                <div className="p-2 text-xs xsm:text-sm">
                  {currentPage * pageSize + key + 1}
                </div>
                <div className="p-2 text-xs xsm:text-sm">
                  {user.heading} 
                </div>
                <div className="p-2 text-xs xsm:text-sm overflow-hidden text-ellipsis">
                  <div style={{
                display: '-webkit-box',
                WebkitLineClamp: 6, 
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }} dangerouslySetInnerHTML={{
                  __html: decodeHtml(user?.content)
                }}
                />
                </div>

                 <div className="p-2 text-xs xsm:text-sm">
                  {user.photo ? (
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}/${user.photo}`}
                      width=""
                      height="100%"
                      alt=""
                    />
                  ) : (
                    <div className="img-wrpr" />
                  )}

                </div>

                <div className="p-2 text-xs xsm:text-sm">
                  {user.phone}
                </div>
                
               
                <div className="p-2 text-xs xsm:text-sm">
                  {user.email}
                </div>

               

                <div className="p-2 text-xs xsm:text-sm">
                  {user.status === "Unpublish" ? (
                    user.photo && user.content && user.heading && user.email && user.phone ? (
                      <button
                        className="px-3 py-1 text-xs font-medium text-red-500 bg-red-100"
                        onClick={() => handlepublish(user.id)}
                      >
                        Unpublished
                      </button>
                    ) 
                    : (!user.email || !user.phone) && user.photo && user.content && user.heading ? (
                      <button
                        className="px-3 py-1 text-xs font-medium text-red-500 bg-red-100"
                        onClick={() => handlepublish(user.id)}
                      >
                        Unpublished
                      </button>
                    )
                    : (!user.photo || !user.content) ? (
                      <button
                        className="px-3 py-1 text-xs font-medium text-yellow-500 bg-yellow-100"
                        onClick={() =>
                          alert("This item is in draft mode and cannot be published until all required fields are filled.")
                        }
                      >
                        Draft
                      </button>
                    ) 
                    : (
                      <button
                        className="px-3 py-1 text-xs font-medium text-green-500 bg-green-100"
                        onClick={() => handlepublish(user.id)}
                      >
                        Published
                      </button>
                    )
                  ) : (
                    <button
                      className="px-3 py-1 text-xs font-medium text-green-500 bg-green-100"
                      onClick={() => handlepublish(user.id)}
                    >
                      Published
                    </button>
                  )}
                </div>
                
                <div className="p-2 text-xs xsm:text-sm">
                  <PencilSquareIcon
                    onClick={() =>navigate(`/editspeaker/${user.id}`)}
                    className="h-6 w-6 text-black-600 cursor-pointer"
                    aria-hidden="true"
                  />
                  <TrashIcon
                    onClick={(e) => handleDelete(e, user.id)}
                    className="h-6 w-6 text-black-600 cursor-pointer hover:text-red-600"
                    aria-hidden="true"
                  />
                  <Eye style={{cursor:'pointer'}} onClick={()=>{handleNavigateWithState(user)}} />
                </div>
              </div>
            ))}
          </div>

          <div className="mt-4 flex justify-center">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPage}
              onPageChange={handlePageChange}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SpeakerListing;