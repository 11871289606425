import React, {useEffect, useState} from 'react'
import Footer from './Footer';
import Navbar from './Navbar';
import InitModal from './initModal';
import { useScrollToTop } from '../hooks/ScrollTop';
import siteQueries from '../queries/siteQueries';
import Pagination from '../common/Pagination/Pagination';
import Loader from '../components/Loader/Loader';
import { useNavigate} from 'react-router-dom';

export default function NewsSite() {

    const [isOpen, setIsOpen] = useState(false);
    const [news, setNews] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [totalPage, setTotalPage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
 
    const navigate = useNavigate();
    const handleClose = () => setIsOpen(false);
    const handleOpen = () => setIsOpen(true);
    
    const newsData = siteQueries.useNewsMutation(
        async (response) => {
          setNews(response?.data?.items);
          setTotalCount(response?.data?.totalItems);
          setTotalPage(response?.data?.totalPages);
          setIsLoading(false);
        },
      );

      useEffect(() => {
        newsData.mutateAsync({
            page: currentPage,
            size: pageSize,
        })
        
      }, [currentPage, pageSize,]);

      const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
      };

      const totalPages = totalPage;

      const handleReadMore = (item) => {
        navigate('/newsview', { state: { item } })
      } 

      const decodeHtml = (html) => {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = html;
        return textArea.value;
      };

  useScrollToTop();
  return (
    <div>
        {isLoading ? (
      <Loader /> 
    ) : (
        <>
        <Navbar handleOpen={handleOpen}  />
        <div class="innerbanner container-fluid">
            <div class="container">

                <div class="row">
                    <div class="col-md-12">
                    <span class="logo-strips"></span> <h2><span>KLF</span> - News</h2>
                    </div>
                </div>
            </div>
        </div>
            <div>
            <div className="container my-4">
                <div className="row">
                    {news.map((item, index) => (
                    <div className="col-12 col-md-6 col-lg-3 mb-4" key={index}>
                        <div className="card h-100 shadow-sm cursor-pointer"
                        style={{ maxHeight: '400px', overflow: 'hidden', cursor: 'pointer'}}
                        onClick={() => handleReadMore(item)}>
                        
                        <img 
                        src={`${process.env.REACT_APP_BASE_URL}/${item.image}`} 
                        className="card-img-top" 
                        style={{ height: '50%', objectFit: 'cover' }}/>
                        <div className="card-body" style={{ height: '30%', overflow: 'hidden' }}>
                            <h5 className="card-title text-truncate">{item.shortdescription}</h5>
                            <span className="card-text"
                            style={{
                                display: '-webkit-box',
                                WebkitLineClamp: 5, 
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              <div
                              dangerouslySetInnerHTML={{
                                __html: decodeHtml(item.maindescription)
                              }}
                              />
                              </span>
                            </div>
                        </div>
                        {/* <div style={{ height: '10%'}}>
                        <button className="btn w-100 text-black border border-dark" 
                            onClick={() => handleReadMore(item)}>
                            Read More
                        </button>
                            </div> */}
                    </div>
                    ))}
                </div>
                
                <div 
                className="d-flex justify-content-center mt-4">
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
                </div>
                </div>
                
            </div>
                
        <Footer handleOpen={handleOpen} />

        <InitModal isOpen={isOpen} onClose={handleClose} />
        </>
    )}
    
    </div>
  )
}

