export const countriesData = [
    
    { 
        name: "Afghanistan",
        states: [   
            "Badakhshan",
            "Badghis",
            "Baghlan",
            "Balkh",
            "Bamyan",
            "Daykundi",
            "Farah",
            "Faryab",
            "Ghazni",
            "Ghor",
            "Helmand",
            "Herat",
            "Jowzjan",
            "Kabul",
            "Kandahar",
            "Kapisa",
            "Khost",
            "Kunar",
            "Kunduz",
            "Laghman",
            "Logar",
            "Nangarhar",
            "Nimruz",
            "Nuristan",
            "Paktia",
            "Paktika",
            "Panjshir",
            "Parwan",
            "Samangan",
            "Sar-e Pol",
            "Takhar",
            "Uruzgan",
            "Wardak",
            "Zabul"
        ]
    
    },
    {
        name: "United Kingdom",
        states: [
            "England",
            "Scotland",
            "Wales",
            "Northern Ireland"
        ]
    },
    {
        name: "Germany",
        states: [
            "Baden-Württemberg",
            "Bavaria",
            "Berlin",
            "Brandenburg",
            "Bremen",
            "Hamburg",
            "Hesse",
            "Lower Saxony",
            "Mecklenburg-Vorpommern",
            "North Rhine-Westphalia",
            "Rhineland-Palatinate",
            "Saarland",
            "Saxony",
            "Saxony-Anhalt",
            "Schleswig-Holstein",
            "Thuringia"
        ]
    },
    {
        name: "France",
        states: [
            "Auvergne-Rhône-Alpes",
            "Bourgogne-Franche-Comté",
            "Brittany",
            "Centre-Val de Loire",
            "Grand Est",
            "Hauts-de-France",
            "Île-de-France",
            "Normandy",
            "Nouvelle-Aquitaine",
            "Occitanie",
            "Pays de la Loire",
            "Provence-Alpes-Côte d'Azur",
            "Corsica"
        ]
    },
    {
        name: "Italy",
        states: [
            "Abruzzo",
            "Basilicata",
            "Calabria",
            "Campania",
            "Emilia-Romagna",
            "Friuli Venezia Giulia",
            "Lazio",
            "Liguria",
            "Lombardy",
            "Marche",
            "Molise",
            "Piedmont",
            "Apulia",
            "Sardinia",
            "Sicily",
            "Tuscany",
            "Umbria",
            "Aosta Valley",
            "Trentino-Alto Adige/Südtirol",
            "Veneto"
        ]
    },
    {
        name: "Spain",
        states: [
            "Andalusia",
            "Aragon",
            "Asturias",
            "Balearic Islands",
            "Basque Country",
            "Canary Islands",
            "Cantabria",
            "Castile and León",
            "Castile-La Mancha",
            "Catalonia",
            "Extremadura",
            "Galicia",
            "La Rioja",
            "Madrid",
            "Murcia",
            "Navarre",
            "Valencian Community"
        ]
    },
    {
        name: "Russia",
        states: [
            "Adygea",
            "Altai Republic",
            "Bashkortostan",
            "Buryatia",
            "Chechen Republic",
            "Chuvash Republic",
            "Dagestan",
            "Ingushetia",
            "Kabardino-Balkar Republic",
            "Kalmykia",
            "Karachay-Cherkess Republic",
            "Karelia",
            "Kemerovo Oblast",
            "Khabarovsk Krai",
            "Krasnodar Krai",
            "Krasnoyarsk Krai",
            "Moscow",
            "Murmansk Oblast",
            "Novgorod Oblast",
            "Novosibirsk Oblast",
            "Omsk Oblast",
            "Orenburg Oblast",
            "Primorsky Krai",
            "Rostov Oblast",
            "Saint Petersburg",
            "Sakha Republic",
            "Sakhalin Oblast",
            "Smolensk Oblast",
            "Stavropol Krai",
            "Tatarstan",
            "Tomsk Oblast",
            "Tula Oblast",
            "Tyumen Oblast",
            "Udmurt Republic",
            "Vladimir Oblast",
            "Volgograd Oblast",
            "Vologda Oblast",
            "Voronezh Oblast",
            "Yamalo-Nenets Autonomous Okrug",
            "Zabaykalsky Krai"
        ]
    },
    {
        name: "China",
        states: [
            "Anhui",
            "Beijing",
            "Chongqing",
            "Fujian",
            "Gansu",
            "Guangdong",
            "Guangxi",
            "Guizhou",
            "Hainan",
            "Hebei",
            "Heilongjiang",
            "Henan",
            "Hong Kong",
            "Hubei",
            "Hunan",
            "Inner Mongolia",
            "Jiangsu",
            "Jiangxi",
            "Jilin",
            "Liaoning",
            "Ningxia",
            "Qinghai",
            "Shaanxi",
            "Shandong",
            "Shanxi",
            "Shanghai",
            "Sichuan",
            "Tianjin",
            "Tibet",
            "Xinjiang",
            "Yunnan",
            "Zhejiang",
            "Macau"
        ]
    },
    { 
        name: "India",
        states: [ 
            "Arunachal Pradesh",
            "Andra Pradesh",
            "Assam",
            "Bihar",
            "Chhattisgarh",
            "Goa",
            "Gujarat",
            "Haryana",
            "Himachal Pradesh",
            "Jharkhand",
            "Karnataka",
            "Kerala",
            "Madhya Pradesh",
            "Maharashtra",
            "Manipur",
            "Meghalaya",
            "Mizoram",
            "Nagaland",
            "Odisha",
            "Punjab",
            "Rajasthan",
            "Sikkim",
            "Tamil Nadu",
            "Telangana",
            "Tripura",
            "Uttar Pradesh",
            "Uttarakhand",
            "West Bengal",
            "Andaman and Nicobar Islands",
            "Chandigarh",
            "Dadra and Nagar Haveli and Daman and Diu",
            "Delhi",
            "Jammu and Kashmir",
            "Ladakh",
            "Lakshadweep",
            "Puducherry"    
    
      ]
    
    },
    {
        name: "Japan",
        states: [
            "Aichi",
            "Akita",
            "Aomori",
            "Chiba",
            "Ehime",
            "Fukui",
            "Fukuoka",
            "Fukushima",
            "Gifu",
            "Gunma",
            "Hiroshima",
            "Hokkaido",
            "Hyogo",
            "Ibaraki",
            "Ishikawa",
            "Okayama",
            "Okinawa",
            "Osaka",
            "Saga",
            "Saitama",
            "Shiga",
            "Shimane",
            "Shizuoka",
            "Tochigi",
            "Tokyo",
            "Tottori",
            "Toyama",
            "Wakayama",
            "Yamagata",
            "Yamaguchi",
            "Yokohama"
        ]
    },
    {
        name: "South Korea",
        states: [
            "Busan",
            "Chungcheongbuk-do",
            "Chungcheongnam-do",
            "Daegu",
            "Daejeon",
            "Gwangju",
            "Gyeonggi-do",
            "Gyeongsangbuk-do",
            "Gyeongsangnam-do",
            "Incheon",
            "Jeju",
            "Jeollabuk-do",
            "Jeollanam-do",
            "Sejong",
            "Seoul",
            "Ulsan"
        ]
    },
    {
        name: "Indonesia",
        states: [
            "Aceh",
            "Bali",
            "Bangka Belitung Islands",
            "Banten",
            "Borneo (Kalimantan)",
            "Gorontalo",
            "Jakarta",
            "Jambi",
            "Jawa Barat (West Java)",
            "Jawa Tengah (Central Java)",
            "Jawa Timur (East Java)",
            "Lampung",
            "Maluku",
            "North Maluku",
            "Nusa Tenggara Barat (West Nusa Tenggara)",
            "Nusa Tenggara Timur (East Nusa Tenggara)",
            "Papua",
            "West Papua",
            "Riau",
            "South Sumatra",
            "South Sulawesi",
            "Southeast Sulawesi",
            "Sumatra",
            "West Sumatra",
            "Yogyakarta"
        ]
    },
    {
        name: "Saudi Arabia",
        states: [
            "Al Bahah",
            "Al Jawf",
            "Al Madinah",
            "Al Qassim",
            "Asir",
            "Eastern Province",
            "Ha'il",
            "Makkah",
            "Najran",
            "Riyadh",
            "Tabuk",
            "Jizan",
            "Northern Borders"
        ]
    },
    {
        name: "Nigeria",
        states: [
            "Abia",
            "Adamawa",
            "Akwa Ibom",
            "Anambra",
            "Bauchi",
            "Bayelsa",
            "Benue",
            "Borno",
            "Cross River",
            "Delta",
            "Ebonyi",
            "Edo",
            "Ekiti",
            "Enugu",
            "Gombe",
            "Imo",
            "Jigawa",
            "Kaduna",
            "Kano",
            "Kogi",
            "Kwara",
            "Lagos",
            "Nasarawa",
            "Niger",
            "Ogun",
            "Ondo",
            "Osun",
            "Oyo",
            "Plateau",
            "Rivers",
            "Sokoto",
            "Taraba",
            "Yobe",
            "Zamfara",
            "Federal Capital Territory (Abuja)"
        ]
    },
    {
        name: "South Africa",
        states: [
            "Eastern Cape",
            "Free State",
            "Gauteng",
            "KwaZulu-Natal",
            "Limpopo",
            "Mpumalanga",
            "Northern Cape",
            "North West",
            "Western Cape"
        ]
    },
    {
        name: "Egypt",
        states: [
            "Alexandria",
            "Aswan",
            "Asyut",
            "Beheira",
            "Beni Suef",
            "Cairo",
            "Dakahlia",
            "Damietta",
            "Faiyum",
            "Gharbia",
            "Giza",
            "Ismailia",
            "Minya",
            "Monufia",
            "New Valley",
            "Port Said",
            "Qalyubia",
            "Qena",
            "Red Sea",
            "Sharqia",
            "Sohag",
            "South Sinai",
            "Suez"
        ]
    },
    {
        name: "Kenya",
        states: [
            "Bomet",
            "Bungoma",
            "Busia",
            "Elgeyo-Marakwet",
            "Embu",
            "Garissa",
            "Homa Bay",
            "Isiolo",
            "Kajiado",
            "Kakamega",
            "Kericho",
            "Kiambu",
            "Kilifi",
            "Kirinyaga",
            "Kisii",
            "Kisumu",
            "Kitui",
            "Laikipia",
            "Lamu",
            "Machakos",
            "Makueni",
            "Mandera",
            "Marsabit",
            "Meru",
            "Migori",
            "Mombasa",
            "Murang'a",
            "Nairobi",
            "Nakuru",
            "Narok",
            "Nyandarua",
            "Nyamira",
            "Nairobi City",
            "Samburu",
            "Siaya",
            "Taita-Taveta",
            "Tana River",
            "Tharaka-Nithi",
            "Trans Nzoia",
            "Turkana",
            "Uasin Gishu",
            "Vihiga",
            "Wajir",
            "West Pokot"
        ]
    },
    {
        name: "Australia",
        states: [
            "Australian Capital Territory",
            "New South Wales",
            "Northern Territory",
            "Queensland",
            "South Australia",
            "Tasmania",
            "Victoria",
            "Western Australia"
        ]
    },
    {
        name: "New Zealand",
        states: [
            "Auckland",
            "Bay of Plenty",
            "Canterbury",
            "Gisborne",
            "Hawke's Bay",
            "Manawatu-Wanganui",
            "Marlborough",
            "Nelson",
            "Northland",
            "Otago",
            "Southland",
            "Taranaki",
            "Tasman",
            "Waikato",
            "Wellington",
            "West Coast"
        ]
    },
    {
        name: "United States",
        states: [
            "Alabama",
            "Alaska",
            "Arizona",
            "Arkansas",
            "California",
            "Colorado",
            "Connecticut",
            "Delaware",
            "Florida",
            "Georgia",
            "Hawaii",
            "Idaho",
            "Illinois",
            "Indiana",
            "Iowa",
            "Kansas",
            "Kentucky",
            "Louisiana",
            "Maine",
            "Maryland",
            "Massachusetts",
            "Michigan",
            "Minnesota",
            "Mississippi",
            "Missouri",
            "Montana",
            "Nebraska",
            "Nevada",
            "New Hampshire",
            "New Jersey",
            "New Mexico",
            "New York",
            "North Carolina",
            "North Dakota",
            "Ohio",
            "Oklahoma",
            "Oregon",
            "Pennsylvania",
            "Rhode Island",
            "South Carolina",
            "South Dakota",
            "Tennessee",
            "Texas",
            "Utah",
            "Vermont",
            "Virginia",
            "Washington",
            "West Virginia",
            "Wisconsin",
            "Wyoming"
        ]
    },
    {
        name: "Canada",
        states: [
            "Alberta",
            "British Columbia",
            "Manitoba",
            "New Brunswick",
            "Newfoundland and Labrador",
            "Nova Scotia",
            "Ontario",
            "Prince Edward Island",
            "Quebec",
            "Saskatchewan",
            "Yukon",
            "Northwest Territories",
            "Nunavut"
        ]
    },
    {
        name: "Mexico",
        states: [
            "Aguascalientes",
            "Baja California",
            "Baja California Sur",
            "Campeche",
            "Chiapas",
            "Chihuahua",
            "Coahuila",
            "Colima",
            "Durango",
            "Guanajuato",
            "Guerrero",
            "Hidalgo",
            "Jalisco",
            "Mexico State",
            "Michoacán",
            "Morelos",
            "Nayarit",
            "Nuevo León",
            "Oaxaca",
            "Puebla",
            "Querétaro",
            "Quintana Roo",
            "San Luis Potosí",
            "Sinaloa",
            "Sonora",
            "Tabasco",
            "Tamaulipas",
            "Tlaxcala",
            "Veracruz",
            "Yucatán",
            "Zacatecas",
            "Mexico City"
        ]
    },
    {
        name: "Brazil",
        states: [
            "Acre",
            "Alagoas",
            "Amapá",
            "Amazonas",
            "Bahia",
            "Ceará",
            "Distrito Federal",
            "Espírito Santo",
            "Goiás",
            "Maranhão",
            "Mato Grosso",
            "Mato Grosso do Sul",
            "Minas Gerais",
            "Pará",
            "Paraíba",
            "Paraná",
            "Pernambuco",
            "Piauí",
            "Rio de Janeiro",
            "Rio Grande do Norte",
            "Rio Grande do Sul",
            "Rondônia",
            "Roraima",
            "Santa Catarina",
            "São Paulo",
            "Sergipe",
            "Tocantins"
        ]
    },
    {
        name: "Argentina",
        states: [
            "Buenos Aires",
            "Catamarca",
            "Chaco",
            "Chubut",
            "Córdoba",
            "Corrientes",
            "Entre Ríos",
            "Formosa",
            "Jujuy",
            "La Pampa",
            "La Rioja",
            "Mendoza",
            "Misiones",
            "Neuquén",
            "Río Negro",
            "Salta",
            "San Juan",
            "San Luis",
            "Santa Cruz",
            "Santa Fe",
            "Santiago del Estero",
            "Tierra del Fuego",
            "Tucumán",
            "Ciudad Autónoma de Buenos Aires"
        ]
    },
    {
        name: "Colombia",
        states: [
            "Amazonas",
            "Antioquia",
            "Arauca",
            "Atlántico",
            "Bolívar",
            "Boyacá",
            "Caldas",
            "Caquetá",
            "Casanare",
            "Cauca",
            "Cesar",
            "Chocó",
            "Córdoba",
            "Cundinamarca",
            "Guainía",
            "Guaviare",
            "Huila",
            "La Guajira",
            "Magdalena",
            "Meta",
            "Nariño",
            "Norte de Santander",
            "Putumayo",
            "Quindío",
            "Risaralda",
            "Santander",
            "Sucre",
            "Tolima",
            "Valle del Cauca",
            "Vaupés",
            "Vichada",
            "Bogotá D.C."
        ]
    },
    {
        name: "Chile",
        states: [
            "Arica and Parinacota",
            "Tarapacá",
            "Antofagasta",
            "Atacama",
            "Coquimbo",
            "Valparaíso",
            "Metropolitan Region of Santiago",
            "O'Higgins",
            "Maule",
            "Ñuble",
            "Biobío",
            "La Araucanía",
            "Los Ríos",
            "Los Lagos",
            "Aysén",
            "Magallanes and Chilean Antarctica"
        ]
    }
    
    ]