import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import userQueries from '../../queries/userQueries';
import Loader from '../Loader/Loader';
import RichTextEditor from "../Rte/Rteeditor";


const EditNews = () => {
    const [susmsg, setSusmsg] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageUploadSuccess, setImageUploadSuccess] = useState(false);
    const [imageError, setImageError] = useState('');
    const [loading, setLoading] = useState(false);
    const imageRef = useRef(null);
    const [content, setContent] = useState('');
    const [contentError,setContentError] = useState('');

    const { id } = useParams();
    const navigate = useNavigate();

    const { register, reset, handleSubmit, getValues,formState: { errors } } = useForm();

    const newsdata = userQueries.useUpdatenewsMutation(
        (response) => {
           
            if (response?.data) {
                let datas = ({
                    id: id,
                    shortdescription: response?.data.shortdescription,
                    maindescription: response?.data.maindescription,
                    publishstatus: response?.data.publishstatus,
                    displayorder: response?.data.displayorder
                });
                setContent(response?.data.maindescription);
                reset({ ...datas });
                setCurrentImage(response?.data.image || null);
            }
        }
    );

    useEffect(() => {
        newsdata.mutateAsync({ id });
    }, [id]);

    const editSpeaker = userQueries.useUpdatenewsMutation(
        (response) => {
            if (response?.message === "Success") {
                setSusmsg(true);
                alert(response.data.publishstatus === '0' ? "Saved as draft" : "Published successfully");
                navigate('/newslist');
            }
            
        }
    );

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            setSelectedImage(e.target.files[0]);
        }
    };

    const handleFormSubmit = async (data, publishStatus) => {
        setLoading(true);  // Disable buttons immediately after submitting

        if(publishStatus === 1)
        {
            
            if (!content) {
                setImageError("");  // Clear image error
                setContentError("Content is required.");  // Set content error
                setLoading(false);
                return false;
            }
            const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
            if(selectedImage){
                if (!allowedTypes.includes(selectedImage.type)) {
                    setImageError("Only JPEG, JPG and PNG files are allowed.");
                    setLoading(false);  // Re-enable buttons if validation fails
                    return false;
                }
            }
            else if(!selectedImage && !currentImage)
            {
                setImageError("Image Required");
                    setLoading(false);  // Re-enable buttons if validation fails
                    return false;
            }
        }

        const datavalues = {
            id: id,
            shortdescription: data.shortdescription,
            maindescription: content,
            news: selectedImage,
            publishstatus: publishStatus,
            displayorder: data.displayorder !== null ? data.displayorder : null

        };
    
        try {
            await editSpeaker.mutateAsync(datavalues);
            
        } catch (error) {
            console.error("Error updating news:", error);
        } finally {
            setLoading(false);  // Re-enable buttons after completion
        }
    };
    
    return (
        <>
            <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
                Update News
            </h4>
            <form className="max-w-lg mx-auto grid grid-cols-2 gap-4">
                <div className="mb-5 col-span-2">
                    <label htmlFor="shortdescription" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Heading <span className="text-sm text-gray-500"></span>
                    </label>
                    <textarea 
                        id="shortdescription" 
                        {...register("shortdescription", { 
                            required: "Heading is required",
                            maxLength: {
                                value: 256,
                                message: "Heading cannot exceed 256 characters"
                            }
                        })} 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                        rows="4"
                    />
                    {errors.shortdescription && <div className="text-red-600 text-s">{errors.shortdescription.message}</div>}
                </div>

                <div className="mb-5 col-span-2">
                    <label htmlFor="maindescription" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Content <span className="text-sm text-gray-500"></span>
                    </label>
                    <RichTextEditor
                        onChange={(value) => setContent(value)}
                        name="content"
                        value={content}
                    />
                    {errors.content && <div className="text-red-600 text-s">{errors.maindescription.message}</div>}
                </div>
                {contentError && <div className="text-red-600 text-s">{contentError}</div>}

                <div className="mb-5 col-span-2">
                    <label htmlFor="image" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Upload Image
                    </label>
                    {currentImage && (
                        <div className="mb-2 text-sm text-gray-600">
                            Current image: {currentImage}
                        </div>
                    )}
                    <input
                        type="file"
                        id="image"
                        ref={imageRef}
                        onChange={handleImageChange}
                        accept="image/*"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    />
                    {selectedImage && (
                        <div className="mt-2 text-sm text-gray-600">
                            Selected: {selectedImage.name}
                        </div>
                    )}
                    {imageError && <div className="text-red-600 text-s">{imageError}</div>}
                    {imageUploadSuccess && (
                        <div className="mt-2 text-sm text-green-600">
                            Image uploaded successfully!
                        </div>
                    )}
                    <span className="form-text text-warning small">
                    Recommended: Use landscape images under 256 KB.
                  </span>
                </div>
                <div className="mb-5">
                    <label htmlFor="displayorder" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Display Order</label>
                    <input 
                        type="number" 
                        id="displayorder" 
                        {...register("displayorder", { required: false })} 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    />
                   
                </div>


                <button 
                    type="button"
                    onClick={() => handleFormSubmit(getValues(), 0)}
                    className="col-span-2 w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-3"
                >
                    Save as draft
                </button>

                <button 
                    type="button"
                    onClick={handleSubmit((data) => handleFormSubmit(data, 1))}
                    className="col-span-2 w-full text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                    Save and publish
                </button>

                {susmsg && (
                    <div className="text-green-600 text-lg ml-6 text-center inline-block whitespace-nowrap col-span-2">
                        News updated successfully
                    </div>
                )}
            </form>
            {loading && (
                <Loader />  
            )}
        </>
    );
};

export default EditNews;
