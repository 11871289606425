import React from 'react'
import DashboardTiles from './Tiles'

export default function Dashboard() {
  return (
    <div>
        <DashboardTiles />
    </div>
  )
}
