
import { useMutation } from "react-query";
import sessionService from "../services/sessionService";


const useVenuelistMutation = (onData) => {
    return useMutation(sessionService.venueList, {
      onSuccess: (data) => {
        onData(data);
      },
    });
};

const useSessionlistMutation = (onData) => {
    return useMutation(sessionService.sessionList, {
      onSuccess: (data) => {
        onData(data);
      },
    });
};


const useSessionCreateMutation = (onData) => {
    return useMutation(sessionService.sessionCreate, {
      onSuccess: (data) => {
        onData(data);
      },
    });
};

const useSessionUpdateMutation = (onData) => {
  return useMutation(sessionService.sessionUpdate, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useSessionViewMutation = (onData) => {
  return useMutation(sessionService.sessionView, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useSessionSpeakerMutation = (onData) => {
  return useMutation(sessionService.sessionSpeaker, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useVenueCreateMutation = (onData) => {
  return useMutation(sessionService.venueCreate, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useVenueUpdateMutation = (onData) => {
  return useMutation(sessionService.venueUpdate, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};


const useSpeakerlistMutation = (onData) => {
  return useMutation(sessionService.speakerList, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};





const sessionQueries = {
    useVenuelistMutation,
    useSessionCreateMutation,
    useSessionlistMutation,
    useSessionSpeakerMutation,
    useVenueCreateMutation,
    useVenueUpdateMutation,
    useSpeakerlistMutation,
    useSessionUpdateMutation,
    useSessionViewMutation
};

export default sessionQueries;
