import React from 'react';
import { FrownIcon } from 'lucide-react';

const NotFoundPage = () => {
  return (
    <div className="flex justify-center items-center h-screen bg-gradient-to-br from-blue-500 to-purple-500">
      <div className="bg-white shadow-xl rounded-lg p-8 w-full max-w-md">
        <div className="flex justify-center mb-6">
          <div className="bg-gradient-to-br from-red-500 to-pink-500 rounded-full p-4">
            <FrownIcon size={32} className="text-white" />
          </div>
        </div>
        <h1 style={{color:'black'}} className="text-4xl font-bold text-center mb-4 text-gray-800">Oops! Page not found.</h1>
        <p className="text-gray-600 text-center mb-8">
          The page you are looking for does not exist or has been moved.
        </p>
        <div className="flex justify-center">
          <a
            href="/"
            className="bg-gradient-to-br from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 text-white font-medium py-3 px-6 rounded-lg shadow-lg transition-colors duration-300"
          >
            Go to Home
          </a>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;