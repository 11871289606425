import React, {useEffect, useState} from 'react'
import Footer from './Footer';
import Navbar from './Navbar';
import InitModal from './initModal';
import { useScrollToTop } from '../hooks/ScrollTop';
import siteQueries from '../queries/siteQueries';
import { useNavigate, useLocation} from 'react-router-dom';


export default function NewsView() {

    const [isOpen, setIsOpen] = useState(false);
    const [news, setNews] = useState([]);
    const handleClose = () => setIsOpen(false);
    const handleOpen = () => setIsOpen(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(4);

     const navigate = useNavigate();
    const location = useLocation();
    const data = location.state?.item;
    const dataId = data.id

    const newsData = siteQueries.useNewsMutation(
        async (response) => {
          setNews(response?.data?.items);
        },
      );

      useEffect(() => {
        newsData.mutateAsync({
            page: currentPage,
            size: pageSize,
        })
      }, [])

      const handleReadMore = (item) => {
        window.scrollTo(0, 0);
        navigate('/newsview', { state: { item } });
    }
    
    const handleBack = () => {
        navigate('/news')
    };

    const decodeHtml = (html) => {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = html;
        return textArea.value;
      };

  useScrollToTop();
  return (
    <div>
        <Navbar handleOpen={handleOpen}  />
        <div class="innerbanner container-fluid">
            <div class="container">

                <div class="row">
                    <div class="col-md-12">
                    <span class="logo-strips"></span> <h2>{data?.shortdescription}</h2>
                    </div>
                </div>
            </div>
        </div>
            <div>
            <div className="container mt-4">
            <div className="row">
                {/* Main Content Area */}
                <div className="col-md-8">
                    
                        <div className="card mb-4">
                            <img
                                src={`${process.env.REACT_APP_BASE_URL}/${data?.image}`}
                                alt={data.shortdescription}
                                className="card-img-top"
                                style={{ height: '650px', objectFit: 'cover' }}
                            />
                            <div className="card-body h-f">
                                <h2 className="card-title">{data?.shortdescription}</h2>
                                 <span className="card-text">
                                 <div
                                    dangerouslySetInnerHTML={{
                                        __html: decodeHtml(data?.maindescription)
                                    }}
                                    />
                                 </span>
                            </div>
                        </div>
                       
                </div>

                {/* SideCard */}
                <div className="col-md-4">
                    {news?.map((item, index) => (
                        item.id !== dataId && (
                        <div className="card mb-3 cursor-pointer" key={index}
                        style={{cursor: 'pointer'}}
                            onClick={() => handleReadMore(item)}>
                            <img
                                src={`${process.env.REACT_APP_BASE_URL}/${item?.image}`}
                                alt={item?.shortdescription}
                                className="card-img-top"
                                style={{ height: '300px', objectFit: 'cover' }}
                            />
                            <div className="card-body">
                                <h5 className="card-title text-truncate">{item?.shortdescription}</h5>
                                <p className="card-text"
                                style={{
                                    display: '-webkit-box',
                                    WebkitLineClamp: 5, 
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  }}
                                  
                                >
                                      <div
                                    dangerouslySetInnerHTML={{
                                        __html: decodeHtml(data?.maindescription)
                                    }}
                                    />
                                    </p>
                              
                            </div>
                        </div>
                    )
                    ))}
                </div>
            </div>
        </div>
                </div>
            
                
        <Footer handleOpen={handleOpen} />

        <InitModal isOpen={isOpen} onClose={handleClose} />

      
    </div>
  )
}

