import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Breadcrumb from '../Breadcrumbs/Breadcrumb';
import Select from '../Select/Select';
import sessionQueries from '../../queries/sessionQueries';
import Switcher from '../ToggleSwitch/SwitchOne';
import { useNavigate, useParams } from 'react-router-dom';
import MultiSelect from '../Multiselect/MultiSelect';
import ErrorModal from '../ErrorModal/Errormodal';


const schema = yup.object().shape({
  name: yup.string().required('Session Name is required'),
  title: yup.string().required('Session Title is required'),
  date: yup.date()
    .transform((curr, orig) => orig === '' ? null : curr)
    .required('Date is required')
    .typeError('Please enter a valid date in DD/MM/YYYY format'),
  time_from: yup.string().required('Start time is required'),
  time_to: yup.string().required('End time is required')
    .test('is-greater', 'End time should be after start time', function (value) {
      const { timeFrom } = this.parent;
      return !timeFrom || !value || value > timeFrom;
    }),
});



export default function SessionForm() {

  const { id } = useParams(); 
  const isEditMode = !!id;

  const [venueList, setVenueList] = useState([]);
  const [enabled, setEnabled] = useState(false);
  const navigate = useNavigate();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [speakerList, setSpeakerList] = useState([]);
    

  useEffect(() => {
    fetchData();
    if (isEditMode) {
      fetchSessionData();
    }
  }, [isEditMode]);

  const fetchData = async () => {
    await venueListing.mutateAsync();
    await speakerListing.mutateAsync();
  };

  const { control, handleSubmit,setValue,reset, watch, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      title: '',
      subject: '',
      description: '',
      date: new Date(),
      time_from: '',
      time_to: '',
      delegative_exclusive: false,
    }
  });

  const statusOptions = [
    { value: 'ACTIVE', label: 'ACTIVE' },
    { value: 'INACTIVE', label: 'INACTIVE' },
  ];



 // const [speakerList] = useState(dummySpeakers);

  const venueListing = sessionQueries.useVenuelistMutation((response) => {
    const venueOptions = response.data.map(item => ({
      value: item.id.toString(),
      label: item.name
    }));
    setVenueList(venueOptions);
  });

  const speakerListing = sessionQueries.useSpeakerlistMutation((response) => {
    const speakerOptions = response?.data?.items?.map(item => ({
      value: item.user_detail.id.toString(),
      label: `${item.user_detail.first_name} ${item.user_detail.last_name}`
    }));
    setSpeakerList(speakerOptions);
  });


  const sessionSpeaker = sessionQueries.useSessionSpeakerMutation((response) => {
    if(response?.message==='Speakers added to session successfully'){
      navigate('/sessionlist');
    }
   
  });

  const fetchSessionData = async () => {
    try {
      const data = await sessionView.mutateAsync(id);
      const sessionData = data.data;
      if (sessionData) {
        reset({

          name: sessionData.name,
          title: sessionData.title,
          subject: sessionData.subject,
          description: sessionData.description,
          date: new Date(sessionData.date),
          time_from: sessionData.time_from,
          time_to: sessionData.time_to,
          delegative_exclusive: sessionData.delegative_exclusive === 'YES',
          status: sessionData.status,
          venu_id: sessionData.venu_id,
          speakers: sessionData.session_speackers.map(speaker => ({
            value: speaker?.user?.id.toString(),
            label: `${speaker?.user?.user_detail?.first_name} ${speaker?.user?.user_detail?.last_name}`
          }))
        });
      }
    } catch (error) {
      console.error('Error fetching session data:', error);
      setErrorMessage('Error fetching session data');
      setIsErrorModalOpen(true);
    }
  };


  // const AddSpeaker = async (sessionId, speakerSelected) => {
  //   // console.log('Selected Speakers:', speakerSelected);
  //   const data = [];
  //   speakerSelected.forEach(speaker => {
  //     const Singlespeaker = {
  //       sessionId: sessionId,
  //       userId: speaker.value
  //     };
  //     data.push(Singlespeaker);
  //   });
  //   sessionSpeaker.mutateAsync(data);
  // };
  const AddSpeaker = async (sessionId, speakerSelected) => {
  // Check if speakerSelected is defined and has elements before proceeding
  if (speakerSelected && speakerSelected.length > 0) {
    const data = speakerSelected.map(speaker => ({
      sessionId: sessionId,
      userId: speaker.value
    }));
    
    // Proceed with mutating the sessionSpeaker
    await sessionSpeaker.mutateAsync(data);
  } else {
    console.log('No speakers selected, skipping speaker assignment.');
  }
};

  const sessionView = sessionQueries.useSessionViewMutation(async (response) => {

  });


  const sessionCreate = sessionQueries.useSessionCreateMutation(async (response) => {
  });

  const sessionUpdate = sessionQueries.useSessionUpdateMutation(async (response) => {
  });


  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
  };
  
  const onSubmit = async (data) => {
    try {
      let response;
      if (isEditMode) {
        // Prepare JSON data for update
        const jsonData = {
          ...data,
          id: id,
          date: data.date.toISOString(),
          delegative_exclusive: data.delegative_exclusive ? 'YES' : 'NO',
          speakers: data.speakers.map(speaker => speaker.value)
        };
        response = await sessionUpdate.mutateAsync( jsonData );
        await AddSpeaker(id, data.speakers);
      } else {
        // For create, we'll keep using FormData
        const formData = new FormData();
        Object.keys(data).forEach(key => {
          if (key === 'date') {
            formData.append(key, data[key].toISOString());
          } else if (key === 'delegative_exclusive') {
            formData.append(key, data[key] ? 'YES' : 'NO');
          } else if (key !== 'speakers') {
            formData.append(key, data[key]);
          }
        });
        response = await sessionCreate.mutateAsync(formData);
      }

      if (response?.message === 'Success') {
        if (!isEditMode) {
          await AddSpeaker(response.data.id, data.speakers);
        }
        navigate('/sessionlist');
      } else {
        setIsErrorModalOpen(true);
        setErrorMessage(isEditMode ? 'Error updating session' : 'Session Already Exists');
      }
    } catch (error) {
      console.error('Error:', error);
      setIsErrorModalOpen(true);
      setErrorMessage('An error occurred');
    }
  };


  const inputClassName = "w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary";

  return (
    <div className="container mx-auto p-6">
       <Breadcrumb pageName={isEditMode ? "Edit Session" : "Add Session"} />

      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label htmlFor="sessionName" className="block text-sm font-medium text-gray-700">
                Session Name
              </label>
              <Controller
                name="name"
                control={control}
                render={({ field }) => <input {...field} className={inputClassName} />}
              />
              {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>}
            </div>
            <div>
              <label htmlFor="sessionTitle" className="block text-sm font-medium text-gray-700">
                Session Title
              </label>
              <Controller
                name="title"
                control={control}
                render={({ field }) => <input {...field} className={inputClassName} />}
              />
              {errors.title && <p className="text-red-500 text-sm mt-1">{errors.title.message}</p>}
            </div>
          </div>

          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            {/* <div>
              <label htmlFor="subject" className="block text-sm font-medium text-gray-700">
                Subject
              </label>
              <Controller
                name="subject"
                control={control}
                render={({ field }) => <input {...field} className={inputClassName} />}
              />
              {errors.subject && <p className="text-red-500 text-sm mt-1">{errors.subject.message}</p>}
            </div> */}

            <div>
              <label className="block text-sm font-medium text-gray-700">Date</label>
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    dateFormat="dd/MM/yyyy"
                    className={inputClassName}
                  />
                )}
              />
              {errors.date && <p className="text-red-500 text-sm mt-1">{errors.date.message}</p>}
            </div>


          </div>



          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label htmlFor="timeFrom" className="block text-sm font-medium text-gray-700">
                Start Time
              </label>
              <Controller
                name="time_from"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    type="time"
                    className={inputClassName}
                  />
                )}
              />
              {errors.time_from && <p className="text-red-500 text-sm mt-1">{errors.time_from.message}</p>}
            </div>
            <div>
              <label htmlFor="timeTo" className="block text-sm font-medium text-gray-700">
                End Time
              </label>
              <Controller
                name="time_to"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    type="time"
                    className={inputClassName}
                  />
                )}
              />
              {errors.time_to && <p className="text-red-500 text-sm mt-1">{errors.time_to.message}</p>}
            </div>
          </div>

          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">


          <div>
              <Controller
                name="venu_id"
                control={control}
                render={({ field }) => (
                  <Select
                    label="Venue"
                    options={venueList}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      setValue('venue', e.target.value);
                    }}
                    placeholder="Select your venue"
                    value={field.value}
                    required={false}
                  />
                )}
              />
              {errors.venue && <p className="text-red-500 text-sm mt-1">{errors.venue.message}</p>}
            </div>

            <div>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <Select
                    label="Status"
                    options={statusOptions}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      setValue('status', e.target.value);
                    }}
                    value={field.value}
                    defaultValue="ACTIVE"  
                    required={true}
                  />
                )}
              />
              {errors.status && <p className="text-red-500 text-sm mt-1">{errors.status.message}</p>}
            </div>

          </div>

          <div>
          <Controller
              name="speakers"
              control={control}
              render={({ field }) => (
          <MultiSelect
            name="speakers"
            control={control}
            options={speakerList}
            isMulti={true}
            label="Speakers"
            placeholder="Select speakers"
            error={errors.speakers?.message}
          />
        )}
        />
          </div>

          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">
              Description
            </label>
            <Controller
              name="description"
              control={control}
              render={({ field }) => <textarea {...field} rows={6} className={inputClassName} />}
            />
            {errors.description && <p className="text-red-500 text-sm mt-1">{errors.description.message}</p>}
          </div>


          <div className="flex items-center space-x-2">
            <Controller
              name="delegative_exclusive"
              control={control}
              render={({ field }) => (
                <Switcher
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              )}
            />
            <label htmlFor="toggle3" className="text-sm font-medium text-gray-700">
              Delegative Exclusive:
            </label>
          </div>

          

          <button
            type="submit"
            className="w-full px-4 py-3 font-bold text-white bg-blue-500 rounded-md hover:bg-blue-700 focus:outline-none focus:shadow-outline"
          >
            {isEditMode ? "Update Session" : "Create Session"}
          </button>
        </form>
      </div>
      <ErrorModal
        isOpen={isErrorModalOpen}
        onClose={closeErrorModal}
        errorMessage={errorMessage}
      />
    </div>
  );
}