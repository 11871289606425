import React, { useState, useRef } from "react";
import userQueries from '../../queries/userQueries';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
const Createphotogallery = () => {
    const [susmsg, setSusmsg] = useState(false);
    const [loading, setLoading] = useState(false); // Loading state
    const navigate = useNavigate();
    const imageRef = useRef(null);
    const [imageError, setImageError] = useState('');

    const { register, handleSubmit, getValues,formState: { errors } } = useForm();

    const AddInfodata = userQueries.useCreatephotogalleryMutation((response) => {
       
        if (response?.message === "Success") {
            setSusmsg(true);
            setLoading(false); // Stop loader
            alert(response.data.publishstatus === 0 ? "Saved as draft" : "Published successfully");
            navigate('/photogallerylist');
        }
        
    });

    const handleFormSubmit = async (data, publishStatus) => {
        setSusmsg(false);
        setLoading(true); // Start loader

        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];

        // Check if file exists
        if(publishStatus === 1)
            {
               
    
                if (!imageRef.current?.files[0]) {
                    setImageError("Image is required.");
                    setLoading(false);
                    return false;
                }
    
                if (!allowedTypes.includes(imageRef.current?.files[0].type)) {
                    setImageError("Only JPEG, JPG, and PNG files are allowed.");
                    setLoading(false);
                    return false;
                }
    
                
            }

        setImageError(""); // Clear error if all validations pass

        const formData = new FormData();
        formData.append('title', data.title);
        formData.append('publishstatus', publishStatus);
        formData.append('displayorder', data.displayorder !== null ? data.displayorder : '1');

        if (imageRef.current?.files[0]) {
            formData.append('photogallery', imageRef.current.files[0]);
        }

        try {
            const createdUser = await AddInfodata.mutateAsync(formData);
            console.log(createdUser);
           
        } catch (error) {
            console.error('Error occurred:', error);
            setLoading(false); // Stop loader if error occurs
        }
    };

    return (
        <>
            <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
                Create Photo Gallery
            </h4>
            <form className="max-w-lg mx-auto grid grid-cols-2 gap-4">

                <div className="mb-5 col-span-2">
                    <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Title</label>
                    <input
                        type="text"
                        id="title"
                        {...register("title", { required: "Title is required" })}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    />
                    {errors.title && <div className="text-red-600 text-s">{errors.title.message}</div>}
                </div>

                <div className="mb-5 col-span-2">
                    <label htmlFor="image" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Upload Image</label>
                    <input
                        type="file"
                        id="image"
                        ref={imageRef} // Using the ref to capture the file
                        accept="image/*"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    />
                    {imageError && <div className="text-red-600 text-s">{imageError}</div>}
                    <span className="form-text text-warning small">
                    Recommended: Use landscape images under 256 KB.
                  </span>
                </div>

                <div className="mb-5">
                    <label htmlFor="displayorder" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Display Order</label>
                    <input 
                        type="number" 
                        id="displayorder" 
                        {...register("displayorder", { required: false })} 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    />
                    
                </div>


                <button 
                    type="button" 
                    onClick={() => handleFormSubmit(getValues(), 0)}
                    className="col-span-2 w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-3"
                >
                    Save as draft
                </button>

                <button 
                    type="button"
                    onClick={handleSubmit((data) => handleFormSubmit(data, 1))}
                    className="col-span-2 w-full text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                    Save and publish
                </button>

                {susmsg && <div className="text-green-600 text-lg ml-6 text-center inline-block whitespace-nowrap">
                    Photo Gallery created successfully
                </div>}

                {/* Loader */}
                {loading && (
                    <Loader />
                )}
            </form>
        </>
    );
};

export default Createphotogallery;
