
import  create  from "zustand";
import { persist } from "zustand/middleware";


export const userStore = create(
    persist((set) => ({
      user: {
          userId: "",
          accessToken: "",
          
      },
      setUser: (userDetails) => {
        set({ user: userDetails });
      },
      userdata: [],
      searchTerm: '',
      searchResults: [],
 
      setUserdata: (userdata) => set({ userdata }),
      setSearchTerm: (term) => set({ searchTerm: term }),
      setSearchResults: (results) => set({ searchResults: results }),
      
    }))
  );