const RouteConstants = { 
    ROOT: '/',
    LOGIN: '/login',
    DASHBOARD: '/dashboard',
    USERLISTING: '/userlisting',
    SPEAKERLISTING: '/speakerlisting',
    SPEAKERVIEW: '/speaker-view',
    ADDSPEAKER: '/adduser',
    SLIDERLISTING: '/sliderlisting',
    SLIDERCREATE: '/createslider',
    EDITSPEAKER: '/editspeaker/:id',
    SESSIONFORM: '/sessionform',
    SESSIONLIST: '/sessionlist',
    VENUELIST: '/venuelist',
    VENUEFORM: '/venueform',
    VENUEEDIT: '/edit-venue/:id',
    SESSIONEDIT: '/edit-session/:id',
    ADMINLIST: '/adminlisting',
    DELEGATELIST: '/delegatelisting',
    CCAVENUE:'/ccavenue',
    DELEGATE:'/delegate-register',
    ATTENDEE:'/attendee-register',
    STUDENT:'/student-register',
    TERMS:'/terms-conditions',
    ABOUT:'/about',
    EDITADMIN:'/adminedit/:id',
    SUCCESS:'/success',
    ATSUCCESS:'/atsuccess',
    STUDENTLIST:'/student',
    FAILED:'/failed',
    REGISTER:'/register',
    STUDENTLISTZOHO:'/studentlist',
    DELEGATELISTZOHO: '/delegatelist',
    USERLISTINGZOHO: '/userlist',
    NEWSLISTING: '/newslist',
    CREATENEWS: '/createnews',
    EDITNEWS:'/editnews/:id',
    VIDEOLISTING: '/videoslist',
    CREATEVIDEOS: '/createvideos',
    EDITVIDEOS:'/editvideos/:id',
    PHOTOGALLERYLISTING: '/photogallerylist',
    CREATEPHOTOGALLERY: '/createphotogallery',
    EDITPHOTOGALLERY:'/editphotogallery/:id',
    VIDEOSITELIST: '/videos',
    GALLERYSITELIST: '/photos',
    NEWSLIST: '/news',
    NEWSVIEW: '/newsview',
    SPEAKERLISTINGSITE: '/speakers',
    SPEAKERVIEWSITE: '/speakerview',
    NOTFOUND:'/notfound'
};
export default RouteConstants
