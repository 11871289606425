import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import userOne from '../images/testimonial-user-1.png';
import anitha from '../images/anitha.jpg';
import pinarai from '../images/pinarayi.jpg';
import tmKrishna from '../images/tmkrishna.jpg';
import adoor from '../images/adoorG.jpg';
import bachik from '../images/bachiK.jpg';
import beena from '../images/dr-beena.jpg';
import guilermo from '../images/Guillermo.jpg';
import manu from '../images/Manu.jpg';
import sachi from '../images/sachithanandan.jpg';

const TestimonialCard = ({ color, content, name, image, designation }) => (
  <div className={`card testimonial-card ${color}`}>
    <div className="testim-user">
      <img src={image} alt={name} />
    </div>
    <div className="testim-cntnt">
      <p>{content}</p>
      <div className="testim-user-name">{name}</div>
      {/* <div className="testim-user-desig">{designation}</div> */}
    </div>
  </div>
);

const Testimonials = () => {
  const testimonials = [
    { color: 'yellow', content: 'I wonder why it took so long for a state like Kerala with so many readers and writers to organize a literature festival', name: 'ANITA NAIR', image: anitha },
    { color: 'green', content: 'The times that we live in because of the existence of social media facebook, twitter everything else we somehow believe ', name: 'T.M. KRISHNA', image: tmKrishna },
    { color: 'yellow', content: 'Was it serendipity or design? UNESCO anointed Kozhikode ‘City of Literature’ just weeks before the 7th edition of Kerala Literature Festival. It could well be the latter. After all, it’s Asia’s largest with 500 speakers and a hundred times as many people crowding its seven venues spread across 500 metres of beach. Yes, one of its hashtags is #festivalonthebeach. Ravi Deecee’s KLF is every litfest creator’s dream. Had I still curated the TOI’s in Mumbai, I’d have died of envy.', name: 'BACHI KARKARIA', image: bachik },
    { color: 'green', content: 'The City’s rich literary heritage, vibrant cultural scene, and commitment to promoting literature and arts played a crucial role in securing this honour. The grand success of KLF was a key catalyst for Kozhikode’s recognition as the ‘City of Literature’ by UNESCO.', name: 'Dr.BEENA PHILIP', image: beena },
    { color: 'orange', content: 'Congratulations for the UNESCO distinction. Its great to know that Kozhikode has been declared City of Literature. I am sure a lot of it has to do with DC Books and KLF', name: 'GUILLERMO RODRIGUEZ MARTIN', image: guilermo  },
    { color: 'yellow', content: 'Kozhikode richly deserves the tag. It has a vibrant literary landscape already, moreover it hosts the annual KLF, a remarkable literature festival that has become one of the most important literature festivals in India. If such recognition has been gained, it is because Malayalis have always loved books and engaged with the world of ideas.', name: 'MANU S PILLAI', image: manu  },
  ];

  return (
    <div className="testimonials-wrpr">
      <div className="container">
        <div className="row">
          <div className="col-md-12 p-0 text-center">
            <h2 >
              <span className="speaker-pattern"></span>
              Testimonials
              <span className="speaker-pattern"></span>
            </h2>
          </div>
          <div className="col-md-12 pt-5 pb-2 mt-0" style={{ position: 'relative' }}>
            <div className="swiper-leftarrow">
              <div className="swiper-button-prev testimonial-prev"></div>
            </div>
            <Swiper
              modules={[Autoplay, Navigation, Pagination]}
              spaceBetween={20}
              slidesPerView={3}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              navigation={{
                nextEl: '.swiper-button-next.testimonial-next',
                prevEl: '.swiper-button-prev.testimonial-prev',
              }}
              pagination={{
                el: '.swiper-pagination.testimonial-pagination',
                clickable: true,
              }}
              breakpoints={{
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20
                  },
                  // when window width is >= 480px
                  480: {
                    slidesPerView: 3,
                    spaceBetween: 30
                  },
                  // when window width is >= 640px
                  640: {
                    slidesPerView: 3,
                    spaceBetween: 40
                  }
              }}
              className="swiper-container testimonials-slide"
            >
            <div class="swiper-wrapper">
              {testimonials.map((testimonial, index) => (
                <SwiperSlide key={index} className="swiper-slide text-center">
                  <TestimonialCard {...testimonial} />
                </SwiperSlide>
              ))}
            </div>
            </Swiper>
            
            <div className="swiper-rightarrow">
              <div className="swiper-button-next testimonial-next"></div>
            </div>
            <div className="swiper-pagination-wrpr">
              <div className="swiper-pagination testimonial-pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;