import React, { useEffect, useState } from 'react'
import sessionQueries from '../../queries/sessionQueries';
import Breadcrumb from '../Breadcrumbs/Breadcrumb';
import Loader from '../Loader/Loader';
import { useNavigate } from 'react-router-dom';
import RouteConstants from '../../constant/Routeconstant';
import { PencilSquareIcon } from '@heroicons/react/24/solid';
import Pagination from '../../common/Pagination/Pagination';

export default function SessionList() {

    const [sessionData, setSessionData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [response, setResponse] = useState();

    const navigate = useNavigate();
   
    useEffect(() => {
      setIsLoading(true);
      sessionListing.mutateAsync({
          page: currentPage,   
          size: pageSize       
      });
  }, [currentPage, pageSize]); 
  
     
    const sessionListing = sessionQueries.useSessionlistMutation((response) => {
        setSessionData(response?.data?.items);
        setTotalCount(response?.data?.totalItems);
        setResponse(response?.data.totalPages);
        setIsLoading(false); 
      });



      const handleAddSession = () => {
        navigate(RouteConstants.SESSIONFORM); 
      };
    
      function formatDate(isoDate) {
        const date = new Date(isoDate);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); 
        const year = date.getUTCFullYear();
      
        return `${day}-${month}-${year}`;
      }

      const handleEditSession = (data) => {
        navigate(`/edit-session/${data?.id}`);
      }

      const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
      };

      let totalPages = response
      // let totalPages = Math.ceil(totalCount / pageSize);
                 
  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <Breadcrumb pageName="Sessions" />
        <button 
          className="bg-blue-500 hover:bg-blue-600 text-white rounded-full p-2 shadow-lg transition duration-300 ease-in-out transform hover:scale-150"
          title='Add Session'
          aria-label="Add Session"
          onClick={handleAddSession}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
          </svg>
        </button>
      </div>

    {isLoading ? (
      <Loader /> 
    ) : (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
    <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
      
    </h4>

    <div className="flex flex-col">
      <div className="border-b border-stroke text-black dark:border-strokedark grid grid-cols-[90px_1fr_1fr_1fr_90px_1fr_1fr] sm:grid-cols-[90px_1fr_1fr_1fr_90px_1fr_1fr] bg-gray-800 dark:bg-meta-4">
      <div className="p-2.5 xl:p-5">
          <h5 className="text-sm font-medium uppercase xsm:text-base">
           Sno
          </h5>
        </div>
        <div className="p-2.5 xl:p-5">
          <h5 className="text-sm font-medium uppercase xsm:text-base">
            Session Name
          </h5>
        </div>
        <div className="p-2.5 xl:p-5 w-full">
          <h5 className="text-sm font-medium uppercase xsm:text-base w-full">
            Title
          </h5>
        </div>
        <div className="p-2.5 xl:p-5">
          <h5 className="text-sm font-medium uppercase xsm:text-base">
            Date
          </h5>
        </div>

        <div className="p-2.5 xl:p-5">
          <h5 className="text-sm font-medium uppercase xsm:text-base">
            Timing
          </h5>
        </div>

        <div className="hidden p-2.5 sm:block xl:p-5">
          <h5 className="text-sm font-medium uppercase xsm:text-base">
            Venue
          </h5>
        </div>
        <div className="hidden p-2.5 sm:block xl:p-5">
          <h5 className="text-sm font-medium uppercase xsm:text-base">
            Action
          </h5>
        </div>
      </div>

      {sessionData?.map((session, key) => (
        <div
          className={`grid grid-cols-[90px_1fr_1fr_1fr_90px_1fr_1fr] sm:grid-cols-[90px_1fr_1fr_1fr_90px_1fr_1fr] bg-gray-200 dark:bg-meta-4 ${
            key === session.length - 1
              ? ''
              : 'border-b border-stroke dark:border-strokedark'
          }`}
          key={key}
        >
          <div className="flex gap-3 p-2.5 xl:p-5">
            <p className="hidden text-black dark:text-white sm:block">
              {currentPage * pageSize + key + 1}
            </p>
          </div>
          <div className="fp-2.5 xl:p-5 overflow-hidden text-ellipsis">
            <p className="hidden text-black dark:text-white sm:block overflow-hidden text-ellipsis">
              {session?.name}
            </p>
          </div>

          <div className="p-2.5 xl:p-5 overflow-hidden text-ellipsis">
            <p className="text-black dark:text-white">{session?.title}</p>
          </div>

          <div className="flex p-2.5 xl:p-5">
            <p className="text-black dark:text-white">{formatDate(session?.date)}</p>
          </div>

          <div className="flex p-2.5 xl:p-5">
            <p className="text-black dark:text-white">{session?.time_from} {session?.time_to}</p>
          </div>

          <div className="p-2.5 xl:p-5 overflow-hidden text-ellipsis">
            <p className="text-black dark:text-white">{session?.venue?.name}</p>
          </div>

          <div className="hidden p-2.5 sm:flex xl:p-5">
               <PencilSquareIcon onClick={()=>{handleEditSession(session)}} className="h-6 w-6 text-black-600 cursor-pointer" aria-hidden="true" />
          </div>
        </div>
      ))}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  </div>
    )}
    </>
  )
}

