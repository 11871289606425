import React, { useState } from "react";
import { useForm } from "react-hook-form";
import userQueries from '../../queries/userQueries';
import { useNavigate, } from 'react-router-dom';

const CreateSlider = () => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [susmsg, setSusmsg] = useState(false);
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, setError, clearErrors } = useForm();

    const AddInfodata = userQueries.useSlidercreateMutation(
        (response) => {
            if (response?.message === "Images added successfully to the slider") {
                setSusmsg(true);
                alert("Slider Created");
                navigate('/sliderlisting')
            }else{
                alert(response.message)
            }
        }
    );

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles(files);
        if (files.length === 0) {
            setError("files", {
                type: "manual",
                message: "At least one file must be selected"
            });
        }else { 
            clearErrors("files");
        }
    };

    const onSubmit = async (data) => {
        if (selectedFiles.length === 0) {
            setError("files", {
                type: "manual",
                message: "At least one file must be selected"
            });
            return;
        }

        const formData = new FormData();
        formData.append('title', data.title);

        selectedFiles.forEach((file) => {
            formData.append('slider', file);
        });


        try {
            const createdSlider = await AddInfodata.mutateAsync(formData);
        } catch (error) {
            console.error("Error creating slider:", error);
        }
    };

    return (
        <>
            <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
                Create Sliders
            </h4>
            <form onSubmit={handleSubmit(onSubmit)} className="max-w-lg mx-auto grid grid-cols-2 gap-4">
                {/* Title input field */}
                <div className="mb-5 col-span-2">
                    <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Title
                    </label>
                    <input 
                        type="text" 
                        id="title" 
                        {...register("title", { 
                            required: "Title is required", 
                            validate: value => value.trim() !== "" || "Title cannot be empty" 
                        })} 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    />
                    {errors.title && <p className="text-red-500 text-s mt-1">{errors.title.message}</p>}
                </div>

                {/* File input field */}
                <div className="mb-5 col-span-2">
                    <label htmlFor="files" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Upload Sliders
                    </label>
                    <input 
                        type="file" 
                        id="files" 
                        name="files" 
                        multiple 
                        accept="image/*" 
                        onChange={handleFileChange} 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    />
                    {errors.files && <p className="text-red-500 text-s mt-1">{errors.files.message}</p>}
                </div>

                {/* Display selected files */}
                {selectedFiles.length > 0 && (
                    <div className="mb-5 col-span-2">
                        <p className="text-sm font-medium text-gray-900 dark:text-white">Selected Files:</p>
                        <ul className="list-disc pl-5">
                            {selectedFiles.map((file, index) => (
                                <li key={index} className="text-sm text-gray-600 dark:text-gray-300">{file.name}</li>
                            ))}
                        </ul>
                    </div>
                )}

                {/* Submit button */}
                <button 
                    type="submit" 
                    className="col-span-2 w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                    Create Slider
                </button>

                {/* Success message */}
                {/* {susmsg && (
                    <div className="col-span-2 text-green-600 text-lg text-center">
                        Slider created successfully
                    </div>
                )} */}
            </form>
        </>
    );
};

export default CreateSlider;
