import React, { useEffect, useState } from 'react'
import sessionQueries from '../../queries/sessionQueries';
import Breadcrumb from '../Breadcrumbs/Breadcrumb';
import Loader from '../Loader/Loader';
import { useNavigate } from 'react-router-dom';
import { PencilSquareIcon } from '@heroicons/react/24/solid'

export default function VenueList() {

    const [venueData, setVenueData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    
   useEffect(() => {
      setIsLoading(true); 
      venuelisting.mutateAsync();
    }, []);

    const venuelisting = sessionQueries.useVenuelistMutation((response) => {
        setVenueData(response?.data);
        setIsLoading(false); 
      });

      const handleAddSession = () => {
        navigate('/venueform'); 
      };

      const handleEditSession = (data) => {
        navigate(`/edit-venue/${data?.id}?name=${data?.name}&place=${data?.place}&status=${data?.status}`);
      }
    
  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <Breadcrumb pageName="Venue" />
        <button 
          className="bg-blue-500 hover:bg-blue-600 text-white rounded-full p-2 shadow-lg transition duration-300 ease-in-out transform hover:scale-150"
          title='Add Session'
          aria-label="Add Session"
          onClick={handleAddSession}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
          </svg>
        </button>
      </div>

    {isLoading ? (
      <Loader /> 
    ) : (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
    <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
      
    </h4>

    <div className="flex flex-col">
      <div className="border-b border-stroke text-black dark:border-strokedark grid grid-cols-[90px_1fr_1fr_1fr_1fr] sm:grid-cols-[90px_1fr_1fr_1fr_1fr] bg-gray-800 dark:bg-meta-4">
      <div className="p-2.5 xl:p-5">
          <h5 className="text-sm font-medium uppercase xsm:text-base">
           Sno
          </h5>
        </div>
        <div className="p-2.5 xl:p-5">
          <h5 className="text-sm font-medium uppercase xsm:text-base">
            Venue Name
          </h5>
        </div>
        <div className="p-2.5 xl:p-5">
          <h5 className="text-sm font-medium uppercase xsm:text-base">
            Location
          </h5>
        </div>
        <div className="p-2.5 xl:p-5">
          <h5 className="text-sm font-medium uppercase xsm:text-base">
            Status
          </h5>
        </div>

        <div className="p-2.5 xl:p-5">
          <h5 className="text-sm font-medium uppercase xsm:text-base">
            Action
          </h5>
        </div>
      </div>

      {venueData?.map((venue, key) => (
        <div
          className={`grid grid-cols-[90px_1fr_1fr_1fr_1fr] sm:grid-cols-[90px_1fr_1fr_1fr_1fr] bg-gray-800 dark:bg-meta-4 ${
            key === venue?.length - 1
              ? ''
              : 'border-b border-stroke dark:border-strokedark'
          }`}
          key={key}
        >
          <div className="p-2.5 xl:p-5">
            <p className="hidden text-black dark:text-white sm:block">
              {key+1}
            </p>
          </div>
          <div className="p-2.5 xl:p-5 overflow-hidden text-ellipsis">
            <p className="hidden text-black dark:text-white sm:block">
              {venue?.name}
            </p>
          </div>
          <div className="p-2.5 xl:p-5 overflow-hidden text-ellipsis">
            <p className="hidden text-black dark:text-white sm:block">
              {venue?.place}
            </p>
          </div>

          <div className="p-2.5 xl:p-5">
            <p className="text-black dark:text-white">{venue?.status}</p>
          </div>


          <div className="hidden p-2.5 sm:flex xl:p-5">
            <PencilSquareIcon onClick={()=>{handleEditSession(venue)}} className="h-6 w-6 text-black-600 cursor-pointer" aria-hidden="true" />
          </div>
        </div>
      ))}
    </div>
  </div>
    )}
    </>
  )
}


