import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import userQueries from '../../queries/userQueries';
import Loader from '../Loader/Loader';
const Editphotogallery = () => {
  
    const [susmsg, setSusmsg] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageUploadSuccess, setImageUploadSuccess] = useState(false);
    const [imageError, setImageError] = useState('');
    const [loading, setLoading] = useState(false);
    const imageRef = useRef(null);

    const { id } = useParams();
    const navigate = useNavigate();

    const { register, reset, handleSubmit,getValues, formState: { errors } } = useForm();

    const photogallerydata = userQueries.useUpdatephotogalleryMutation(
        (response) => {
            console.log("response is",response)
            if (response?.data) {
                let datas = ({
                    id: id,
                    title: response?.data.title,
                    displayorder: response?.data.displayorder,
                });
                reset({ ...datas });
                setCurrentImage(response?.data.image || null);
            }
        }
    );

    useEffect(() => {
        photogallerydata.mutateAsync({ id });
    }, [id]);

    const editphotogallery = userQueries.useUpdatephotogalleryMutation(
        (response) => {
            if (response?.message === "Success") {
                setSusmsg(true);
                alert(response?.data.publishstatus === '0' ? "Saved as draft" : "Published successfully");
                navigate('/photogallerylist');
            }
            else if(response?.message === "The display order already exists.")
            {
                alert("Display order already exists.");
                setLoading(false);
            }
        }
    );

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            setSelectedImage(e.target.files[0]);
        }
    };

    const handleFormSubmit = async (data, publishStatus) => {
        setLoading(true);; // Start loading

        if(publishStatus === 1)
        {
                
           
            const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
            if(selectedImage){
                if (!allowedTypes.includes(selectedImage.type)) {
                    setImageError("Only JPEG, JPG and PNG files are allowed.");
                    setLoading(false);  // Re-enable buttons if validation fails
                    return false;
                }
            }
            else if(!selectedImage && !currentImage)
            {
                setImageError("Image Required");
                setLoading(false);  // Re-enable buttons if validation fails
                return false;
            }
        }

        const datavalues = {
            id: id,
            title: data.title,
            photogallery: selectedImage,
            publishstatus: publishStatus,
            displayorder: data.displayorder

        };
    
        try {
            await editphotogallery.mutateAsync(datavalues);
           
        } catch (error) {
            console.error("Error updating photogallery:", error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    return (
        <>
            <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
                Update Photo Gallery
            </h4>
            <form className="max-w-lg mx-auto grid grid-cols-2 gap-4">
                <div className="mb-5 col-span-2">
                    <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Title</label>
                    <input 
                        type="text" 
                        id="title" 
                        {...register("title", { required: "Title is required" })} 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    />
                    {errors.title && <div className="text-red-600 text-s">{errors.title.message}</div>}
                </div>

                {/* New Image Upload Section */}
                <div className="mb-5 col-span-2">
                    <label htmlFor="image" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Upload Image
                    </label>
                    {currentImage && (
                        <div className="mb-2 text-sm text-gray-600">
                            Current image: {currentImage}
                        </div>
                    )}
                    <input
                        type="file"
                        id="image"
                        ref={imageRef}
                        onChange={handleImageChange}
                        accept="image/*"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    />
                    {selectedImage && (
                        <div className="mt-2 text-sm text-gray-600">
                            Selected: {selectedImage.name}
                        </div>
                    )}
                    {imageError && <div className="text-red-600 text-s">{imageError}</div>}
                    {imageUploadSuccess && (
                        <div className="mt-2 text-sm text-green-600">
                            Image uploaded successfully!
                        </div>
                    )}
                    <span className="form-text text-warning small">
                    Recommended: Use landscape images under 256 KB.
                  </span>
                </div>

                <div className="mb-5">
                    <label htmlFor="displayorder" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Display Order</label>
                    <input 
                        type="number" 
                        id="displayorder" 
                        {...register("displayorder", { required: false })} 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    />
                   
                </div>


                {/* Save as Draft Button */}
                <button 
                    type="button"
                    onClick={() => handleFormSubmit(getValues(), 0)}
                    className="col-span-2 w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-3"
                >
                    Save as draft
                </button>

                <button 
                    type="button"
                    onClick={handleSubmit((data) => handleFormSubmit(data, 1))}
                    className="col-span-2 w-full text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                    Save and publish
                </button>

                {susmsg && (
                    <div className="text-green-600 text-lg ml-6 text-center inline-block whitespace-nowrap col-span-2">
                        Photo Gallery updated successfully
                    </div>
                )}
            </form>
            {loading && <Loader />}
        </>
    );
};

export default Editphotogallery;
