import React, { useState, useEffect } from 'react';
import userQueries from '../../queries/userQueries';
import { useNavigate } from 'react-router-dom'; 
import Loader from '../Loader/Loader';

const SliderListing = () => {
  const [sliders, setSliders] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const sliderlist = userQueries.useSliderlistingMutation(
    async (response) => {
      setSliders(response?.data);
      setIsLoading(false);
    },
    {
      onError: (error) => {
        console.error('Userlist fetch error:', error);
        setError('Failed to fetch user list. Please try again later.');
      }
    }
  );

  useEffect(() => {
    setIsLoading(true);
    fetchSliders();
  }, []);

  const fetchSliders = () => {
    sliderlist.mutate();
  };

  return (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
        {/* Header with Title centered and Button aligned to the right */}
        <div className="flex justify-between items-center mb-6">
            <div className="flex-grow text-center">
            <h4 className="text-xl font-semibold text-black dark:text-white">
                Sliders
            </h4>
            </div>
            {/* Create Slider Button aligned to the right */}
            <button
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5"
            onClick={() => navigate('/createslider')} // Navigate to the create slider page
            >
            Create Slider
            </button>
        </div>
        {isLoading ? (
      <Loader /> 
    ) : (
        <>
      <div className="flex flex-col">
        <div className="grid grid-cols-3 rounded-sm bg-gray-2 dark:bg-meta-4 sm:grid-cols-6">
          <div className="p-2.5 xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              Sl no.
            </h5>
          </div>
          <div className="p-2.5 xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              Title
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              Image
            </h5>
          </div>
        </div>

        {sliders && sliders?.length > 0 ? (
            sliders?.map((user, key) => (
              <div
                className={`grid grid-cols-3 sm:grid-cols-6 ${
                  key === 1 ? '' : 'border-b border-stroke dark:border-strokedark'
                }`}
                key={key}
              >
                <div className="flex items-center gap-3 p-2.5 xl:p-5">
                  <p className="hidden text-black dark:text-white sm:block">
                    {key + 1}
                  </p>
                </div>
                <div className="flex items-center gap-3 p-2.5 xl:p-5">
                  <p className="hidden text-black dark:text-white sm:block">
                    {user.title}
                  </p>
                </div>
                <div className="flex items-center gap-3 p-2.5 xl:p-5">
                  {user.photo ? (
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}/${user.photo}`}
                      width=""
                      height="100%"
                      alt=""
                    />
                  ) : (
                    <div className="img-wrpr" />
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className="p-5">
              <p className="text-center text-black dark:text-white">
                No sliders available.
              </p>
            </div>
          )}
      </div>
      </>
      )}
    </div>
  );
};

export default SliderListing;
